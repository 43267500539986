// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".vTable_QeMeH {\n  font: normal 16px/20px var(--vf-Inter);\n  color: #394756;\n  border: 1px solid var(--color-base100);\n}\n.vTable_QeMeH .header_1f0j4 {\n  text-align: left;\n}\n.vTable_QeMeH .row_khGzU {\n  font: normal 16px/20px var(--vf-Inter);\n  color: #394756;\n  border-top: 1px solid #e8ecf0;\n  border-left: none;\n  border-right: none;\n}\n.vTable_QeMeH .row_khGzU.even_1btSk {\n  background-color: #f9fafb;\n}\n.vTable_QeMeH .headerCell_2T5Um {\n  padding: var(--size-pad12) var(--size-padLarge);\n}\n.vTable_QeMeH .bodyCell_BCpo2 {\n  padding: var(--size-pad12) var(--size-padLarge);\n}\n", ""]);
// Exports
exports.locals = {
	"vTable": "vTable_QeMeH",
	"header": "header_1f0j4",
	"row": "row_khGzU",
	"even": "even_1btSk",
	"headerCell": "headerCell_2T5Um",
	"bodyCell": "bodyCell_BCpo2"
};
module.exports = exports;
