/* eslint-disable no-param-reassign */
import Path from '@leverege/path'
import { Plugins } from '@leverege/plugin'
import { Attributes } from '@leverege/ui-attributes'
import StringUtil from '@leverege/string-util'
import Blueprint from './Blueprint'

import DefaultTableModel from './DefaultTableModel'

export default class BlueprintAnalyzer {

  constructor( blueprints, options ) {
    this.options = options
    this.blueprints = blueprints.map( b => new Blueprint( b, options ) )
    this.lookup = this.blueprints.reduce( ( accum, b ) => {
      accum[b.id] = b
      accum[b.type] = b
      return accum
    }, { } )

    this.attrs = { }
    // this.childAttrs = { }
  }

  installAttributes( ) {
    this.blueprints.forEach( ( b ) => {
      const attrs = this.getAttrsFor( b )
      attrs.forEach( ( attr ) => {
        // console.log( `Install Attribute ${attr.name}` )
        Plugins.add( 'Attribute', attr )
      } )
    } )
  }

  installModelCreator() {
    this.blueprints.forEach( ( b ) => {
      DefaultTableModel.create( b )
    } )
  }

  /**
   * Returns the ui-attributes Attribute objects for the
   * blueprint id. This will build them if they have not
   * been make
   */
  getAttrsFor( blueprint ) {
    if ( this.attrs[blueprint.id] == null ) {
      const arr = []
      this.attrs[blueprint.id] = arr

      const { local, child, parent } = blueprint.attributes
      const { name, geoPosition, icon, possName, possGeoPosition } = blueprint.namedAttributes
      const ignore = []
      if ( name ) {
        arr.push( this.toAttribute( blueprint, name, 'name' ) )
        ignore.push( name )
      } else if ( possName ) {
        arr.push( this.toAttribute( blueprint, possName, 'name' ) )
        ignore.push( possName )
      }
      if ( icon ) {
        arr.push( this.toAttribute( blueprint, icon, 'icon' ) )
      }
      if ( geoPosition ) {
        arr.push( this.toAttribute( blueprint, geoPosition, 'geoPosition' ) )
        ignore.push( geoPosition )
      } else if ( possGeoPosition ) {
        arr.push( this.toAttribute( blueprint, possGeoPosition, 'geoPosition' ) )
        ignore.push( possGeoPosition )
      }


      local.forEach( ( attr ) => {
        if ( ignore.includes( attr ) ) {
          return
        }
        const att = this.toAttribute( blueprint, attr )
        if ( att ) { arr.push( att ) }
      } )

      this.toDeviceAttributes( blueprint, name == null && possName == null ).forEach( att => arr.push( att ) )

      parent.forEach( ( attr ) => {
        const att = this.toAttribute( blueprint, attr )
        if ( att ) { arr.push( att ) }
      } )

      child.forEach( ( attr ) => {
        const { targetBlueprintId } = attr
        const fAttrs = this.getAttrsFor( this.lookup[targetBlueprintId] )
        // console.log( 'MAKEING Forwarding Attributes')
        fAttrs.forEach( ( fAttr ) => {
          if ( attr.forwardData ) {
            const att = this.toForwardingAttribute( blueprint, attr, fAttr )
            if ( att ) { arr.push( att ) }
          } else {
            // do other type data source lookup
          }
        } )
      } )
    }
    return this.attrs[blueprint.id]
  }

  installDataSources() {
    // Add createDataSources
    this.blueprints.forEach( ( b ) => {
      // console.log( `Installing DataSource for ${b.type}` )
      Plugins.add( 'DataSource', { type : b.type, dataSource : b.getDataSource() } )
    } )
  }

  install() {
    this.installDataSources()
    this.installAttributes()
    this.installModelCreator()
  }

  toDeviceAttributes( blueprint, addName ) {
    const rtn = [
      {
        id : `${blueprint.type}.deviceName`,
        name : `${blueprint.type}Header.deviceName`,
        displayName : 'System Device Name',
        valueType : 'string',
        objectType : blueprint.type,
        layout : {
          group : [ 'Device' ]
        },
        get : obj => obj.name
      },
      {
        id : `${blueprint.type}.deviceId`,
        name : `${blueprint.type}Header.deviceId`,
        displayName : 'System Device Id',
        valueType : 'string',
        objectType : blueprint.type,
        layout : {
          group : [ 'Device' ]
        },
        get : obj => obj.id
      }
    ]
    if ( addName ) {
      rtn.push( { ...rtn[0], id : `${blueprint.type}.name`, layout : {}, name : 'name', displayName : 'Name' } )
    }

    this.options.networks?.forEach( ( net ) => { 
      const tag = `network:${net.id}`
      if ( blueprint.tags?.includes( tag ) ) {
        const keys = net.aliasKeys?.split( ',' ) || []
        keys.forEach( ( key ) => {
          rtn.push( {
            name : `${blueprint.type}.${net.id}.${key}`,
            displayName : StringUtil.camelCaseToTitleCase( `${key}` ),
            valueType : 'string',
            objectType : blueprint.type,
            layout : {
              group : [ 'Network', net.name ]
            },
            get : obj => obj?.networkAliases?.[net.id]?.[key] || null
          } )
        } )
      }

    } )

    return rtn
  }

  toAttribute( blueprint, attr, rename ) {
    const { path, name, type } = attr
    const group = path.split( '/' )
    group.pop()
    
    const rtn = {
      id : `${blueprint.type}.${name}`,
      name : rename || `${blueprint.type}.${path.replace( /\//g, '.' )}`,
      displayName : StringUtil.camelCaseToTitleCase( name ),
      valueType : type,
      objectType : blueprint.type,
      layout : {
        group
      },
      // ApiAttribute data live under data.
      get : this.createGetter( attr, `data/${path}` )
    }
    return rtn
  }

  /**
   * Create an attribute that will look up data from another
   * attribute
   * @param {Blueprint} blueprint
   * @param {object} attr the api-attribute attribute
   * @param {object} fAttr the ui-attribute attribute
   */
  toForwardingAttribute( blueprint, attr, fAttr ) {
    const { path, name } = attr
    const group = [ path.split( '/' ), ...( fAttr.layout.group || [] ) ]
    
    const forward = this.createGetter( attr, `${path}/data` )
    const forwardName = fAttr.name
    const forwardObjectType = fAttr.objectType
    
    return {
      name : `${blueprint.type}.${path.replace( /\//g, '.' )}$${fAttr.name}`,
      displayName : `${name} ${fAttr.displayName}`,
      valueType : fAttr.type,
      objectType : blueprint.type,
      layout : {
        group
      },
      get : ( obj, cxt ) => { 
        const data = forward( obj, cxt )
        if ( data == null ) {
          return null
        }
        return Attributes.get( forwardName, forwardObjectType, data, cxt )
      }
    }
  }


  createGetter( attr, path ) {
    let accessor 
    if ( path.includes( '/' ) ) { 
      const p = Path( path )
      accessor = ( obj ) => { return p.get( obj ) }
    } else {
      accessor = ( obj ) => { return obj == null ? null : obj[path] }
    }
    if ( attr.type === 'timestamp' ) {
      return ( obj ) => {
        const d = accessor( obj )
        return d == null ? null : new Date( d ) 
      }
    }

    // hacky way to look for unit - use api-attributes
    if ( attr.base != null ) {
      return ( obj ) => {
        const d = accessor( obj )
        return d == null ? null : {
          type : attr.type,
          value : d,
          unit : attr.base
        }
      }
    }

    return ( obj ) => { 
      return accessor( obj )
    }
  }
}
