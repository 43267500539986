// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table_1qkpz.ReactTable {\n  font: normal 16px/20px var(--vf-Inter);\n  color: var(--color-base700);\n  border: 1px solid var(--color-base100);\n  overflow: hidden;\n  background-color: var(--color-base025);\n}\n.table_1qkpz.ReactTable .rt-thead {\n  z-index: 1;\n  box-shadow: none;\n  border-bottom: 1px solid var(--color-base100);\n  background-color: var(--color-white);\n  border-left-width: 0px;\n  border-right-width: 0px;\n  border-right-style: solid;\n}\n.table_1qkpz.ReactTable .rt-thead .rt-th {\n  padding: var(--size-pad12) var(--size-padLarge);\n  outline: none !important;\n  text-align: left;\n  font-weight: 600;\n  text-transform: capitalize;\n}\n.table_1qkpz.ReactTable .rt-thead .rt-th.-sort-desc {\n  box-shadow: inset 0px -1px var(--color-brand500);\n}\n.table_1qkpz.ReactTable .rt-thead .rt-th.-sort-asc {\n  box-shadow: inset 0px 1px var(--color-brand500);\n}\n.table_1qkpz.ReactTable .rt-tbody .rt-tr-group .rt-tr.-odd {\n  background-color: var(--color-base025);\n}\n.table_1qkpz.ReactTable .rt-tbody .rt-tr-group .rt-tr.-even {\n  background-color: var(--color-white);\n}\n.table_1qkpz.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {\n  padding: var(--size-pad12) var(--size-padLarge);\n}\n.table_1qkpz.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:last-child {\n  border-right: none;\n}\n.table_1qkpz.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child {\n  border-left: none;\n}\n", ""]);
// Exports
exports.locals = {
	"table": "table_1qkpz"
};
module.exports = exports;
