// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".portal_3IyIB {\n  background-color: rgba(0, 0, 0, 0.25);\n}\n.portal_3IyIB.portal-dialog_RM41P {\n  background: linear-gradient(0deg, rgba(255, 255, 255, 0.25) 0%, rgba(128, 128, 128, 0.25) 100%), rgba(0, 0, 0, 0.25);\n}\n", ""]);
// Exports
exports.locals = {
	"portal": "portal_3IyIB",
	"portal-dialog": "portal-dialog_RM41P"
};
module.exports = exports;
