// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".textInput_2ne9n {\n  font: normal 16px/22px var(--vf-Inter);\n  color: var(--color-base700);\n  border: 1px solid var(--color-base200);\n  padding: var(--size-padMedium) var(--size-pad12);\n  transition: background-color var(--time-fast), border-bottom-color var(--time-medium), border-left-color var(--time-medium), border-right-color var(--time-medium), border-top-color var(--time-medium), color var(--time-fast), transform var(--time-fast);\n  min-height: 40px;\n  border-radius: 4px;\n  background-color: var(--color-white);\n}\n.textInput_2ne9n:focus {\n  box-shadow: 0 1px 3px var(--color-shadowBase);\n  border-top-color: var(--color-brand300);\n  border-left-color: var(--color-brand300);\n  border-right-color: var(--color-brand300);\n  border-bottom-color: var(--color-brand300);\n}\n.textInput_2ne9n:hover {\n  border-top-color: var(--color-brand300);\n  border-left-color: var(--color-brand300);\n  border-right-color: var(--color-brand300);\n  border-bottom-color: var(--color-brand300);\n}\n.textInput_2ne9n[disabled] {\n  color: var(--color-base300);\n  background-color: var(--color-base100);\n  border-top-color: var(--color-base200);\n  border-left-color: var(--color-base200);\n  border-right-color: var(--color-base200);\n  border-bottom-color: var(--color-base200);\n}\n.textInput_2ne9n[data-error=true] {\n  border-top-color: var(--color-danger500);\n  border-left-color: var(--color-danger500);\n  border-right-color: var(--color-danger500);\n  border-bottom-color: var(--color-danger500);\n}\n.textInput_2ne9n::placeholder {\n  font: normal normal 16px/22px var(--vf-Inter);\n  color: var(--color-base300);\n}\n.textInput_2ne9n.textInput-inputGrow_3xZun {\n  width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"textInput": "textInput_2ne9n",
	"textInput-inputGrow": "textInput-inputGrow_3xZun"
};
module.exports = exports;
