// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".breadcrumbs_6m7zp > .link_19TaQ {\n  font: normal 15px/24px var(--fontFamily-primary);\n  color: var(--color-base300);\n  padding: 0px;\n  outline: none;\n  display: block;\n  transition: background-color var(--time-medium);\n  background-color: transparent;\n  border-top-width: 0px;\n  border-left-width: 0px;\n  border-right-width: 0px;\n  border-bottom-width: 0px;\n}\n.breadcrumbs_6m7zp > .link_19TaQ:hover {\n  font: normal 15px/24px var(--fontFamily-primary);\n  color: var(--color-linkNormal);\n}\n.breadcrumbs_6m7zp > .link_19TaQ:focus {\n  font: normal 15px/24px var(--fontFamily-primary);\n  color: var(--color-base300);\n}\n.breadcrumbs_6m7zp > .link_19TaQ:active {\n  font: normal 15px/24px var(--fontFamily-primary);\n  color: var(--color-linkNormal);\n}\n.breadcrumbs_6m7zp > .separator_1ssdQ {\n  color: var(--color-base300);\n  padding: var(--size-padSmall);\n  font-size: 16px;\n}\n.breadcrumbs_6m7zp > .current_1x-Ne {\n  font: normal 15px/24px var(--vf-Inter);\n  color: var(--color-base500);\n}\n", ""]);
// Exports
exports.locals = {
	"breadcrumbs": "breadcrumbs_6m7zp",
	"link": "link_19TaQ",
	"separator": "separator_1ssdQ",
	"current": "current_1x-Ne"
};
module.exports = exports;
