// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".list_14Lxp > .listItem_22oFC {\n  color: var(--color-base700);\n  border: 1px solid transparent;\n  padding: 8px var(--size-padLarge) var(--size-padLarge);\n  font-size: var(--size-fontBodySmall);\n  font-weight: 600;\n  background-color: var(--color-white);\n}\n.list_14Lxp > .listItem_22oFC[data-list-item-selected=true] {\n  background-color: var(--color-brand100);\n}\n.list_14Lxp > .listItem_22oFC[data-disabled] {\n  color: var(--color-base500);\n  border: 1px solid var(--color-base200);\n  background-color: var(--color-base050);\n}\n", ""]);
// Exports
exports.locals = {
	"list": "list_14Lxp",
	"listItem": "listItem_22oFC"
};
module.exports = exports;
