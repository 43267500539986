// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".label_3NmcK {\n  font: normal 16px/26px var(--vf-Inter);\n  color: var(--color-base500);\n}\n.label_3NmcK.label-inputTitle_3UjPQ {\n  font: 600 13px/20px var(--vf-Inter);\n  color: var(--color-base700);\n}\n.label_3NmcK.label-inputCaption_9KkQo {\n  font: 13px/20px var(--vf-Inter);\n  color: #67798e;\n}\n", ""]);
// Exports
exports.locals = {
	"label": "label_3NmcK",
	"label-inputTitle": "label-inputTitle_3UjPQ",
	"label-inputCaption": "label-inputCaption_9KkQo"
};
module.exports = exports;
