// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".textArea_2jBwb {\n  font: normal 16px/22px var(--vf-Inter);\n  color: var(--color-base700);\n  border: 1px solid var(--color-base200);\n  padding: var(--size-medium);\n  outline: none;\n  transition: background-color 0.2s, color 0.2s, transform 0.2s;\n  border-radius: 4px;\n  background-color: var(--color-white);\n}\n.textArea_2jBwb:focus {\n  box-shadow: 0 1px 3px var(--color-shadowBase);\n  border-top-color: var(--color-brand300);\n  border-left-color: var(--color-brand300);\n  border-right-color: var(--color-brand300);\n  border-bottom-color: var(--color-brand300);\n}\n.textArea_2jBwb:hover {\n  border-top-color: var(--color-brand300);\n  border-left-color: var(--color-brand300);\n  border-right-color: var(--color-brand300);\n  border-bottom-color: var(--color-brand300);\n}\n.textArea_2jBwb[readonly] {\n  color: var(--color-base300);\n  background: linear-gradient(var(--color-base100), var(--color-base100)), var(--color-base100);\n  border-top-color: var(--color-base300);\n  border-left-color: var(--color-base300);\n  border-right-color: var(--color-base300);\n  border-bottom-color: var(--color-base300);\n}\n.textArea_2jBwb[data-error=true] {\n  border-top-color: var(--color-danger500);\n  border-left-color: var(--color-danger500);\n  border-right-color: var(--color-danger500);\n  border-bottom-color: var(--color-danger500);\n}\n.textArea_2jBwb::placeholder {\n  color: var(--color-base300);\n  font-size: var(--size-inputMedium);\n  font-style: italic;\n}\n", ""]);
// Exports
exports.locals = {
	"textArea": "textArea_2jBwb"
};
module.exports = exports;
