import React from 'react'
import { Content } from '@leverege/ui-elements'
import { GridLoader } from 'react-spinners'

export default function LoadingScreen( { background, color } ) {
  return (
    <Content>
      <Content.Area style={{ background : background || 'var( --color-brand500 )', display : 'flex', alignItems : 'center', justifyContent : 'center' }}>
        <GridLoader size={30} color={color || 'var( --color-base200 )'} />
      </Content.Area>
    </Content>
  )
}
