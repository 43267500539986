// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dataBlock_1HMb9 {\n  padding: var(--size-padLarge);\n  box-shadow: 0 1px 3px var(--color-shadowBase);\n  border-radius: var(--size-padMedium);\n  background-color: var(--color-white);\n  border-top-width: 1px;\n  border-top-color: var(--color-base900);\n  border-left-width: 1px;\n  border-left-color: var(--color-base900);\n  border-right-width: 1px;\n  border-right-color: var(--color-base900);\n  border-bottom-width: 1px;\n  border-bottom-color: var(--color-base900);\n}\n.dataBlock_1HMb9 .title_2lwaR {\n  font: bold 16px/24px var(--vf-Inter);\n  color: var(--color-base700);\n  padding-top: 0px;\n  padding-bottom: var(--size-padMedium);\n}\n.dataBlock_1HMb9 .row_2IC_l {\n  padding-top: var(--size-padLarge);\n  border-bottom: 1px solid var(--color-base100);\n  padding-bottom: var(--size-padLarge);\n}\n.dataBlock_1HMb9 .row_2IC_l:last-child {\n  padding-bottom: 0px;\n  border-bottom-width: 0px;\n}\n.dataBlock_1HMb9 .row_2IC_l .label_sdRIS {\n  font: 13px/20px var(--vf-Inter);\n  color: var(--color-base500);\n}\n.dataBlock_1HMb9 .row_2IC_l .value_3Jd3T {\n  font: normal 600 15px/24px var(--vf-Inter);\n  color: var(--color-base700);\n}\n.dataBlock_1HMb9.dataBlock-secondary_1acCr .row_2IC_l {\n  width: 33%;\n  display: flex;\n  margin-top: var(--size-medium);\n  align-items: flex-start;\n  flex-direction: column;\n  justify-content: flex-start;\n  border-bottom-width: 0px;\n}\n.dataBlock_1HMb9.dataBlock-secondary_1acCr .row_2IC_l .label_sdRIS {\n  margin-bottom: var(--size-medium);\n}\n.dataBlock_1HMb9.dataBlock-secondary_1acCr .row_2IC_l > * + * {\n  margin: 0px 0 0 0;\n}\n", ""]);
// Exports
exports.locals = {
	"dataBlock": "dataBlock_1HMb9",
	"title": "title_2lwaR",
	"row": "row_2IC_l",
	"label": "label_sdRIS",
	"value": "value_3Jd3T",
	"dataBlock-secondary": "dataBlock-secondary_1acCr"
};
module.exports = exports;
