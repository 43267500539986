import Theme from './Theme.css'
import Body from './Body.css'
import BodySupport from './BodySupport'
import Breadcrumbs from './Breadcrumbs.css'
import BreadcrumbsSupport from './BreadcrumbsSupport'
import Button from './Button.css'
import ButtonSupport from './ButtonSupport'
import Checkbox from './Checkbox.css'
import CheckboxSupport from './CheckboxSupport'
import Combobox from './Combobox.css'
import ComboboxSupport from './ComboboxSupport'
import DataBlock from './DataBlock.css'
import DataBlockSupport from './DataBlockSupport'
import DateTimePicker from './DateTimePicker.css'
import DateTimePickerSupport from './DateTimePickerSupport'
import DateTimeRangePicker from './DateTimeRangePicker.css'
import DateTimeRangePickerSupport from './DateTimeRangePickerSupport'
import Dropdown from './Dropdown.css'
import DropdownSupport from './DropdownSupport'
import ElementSupport from './ElementSupport'
import Flex from './Flex.css'
import FlexSupport from './FlexSupport'
import Label from './Label.css'
import LabelSupport from './LabelSupport'
import List from './List.css'
import ListSupport from './ListSupport'
import MultiSelect from './MultiSelect.css'
import MultiSelectSupport from './MultiSelectSupport'
import Pane from './Pane.css'
import PaneSupport from './PaneSupport'
import Popup from './Popup.css'
import PopupSupport from './PopupSupport'
import Portal from './Portal.css'
import PortalSupport from './PortalSupport'
import RadioButton from './RadioButton.css'
import RadioButtonSupport from './RadioButtonSupport'
import Slider from './Slider.css'
import SliderSupport from './SliderSupport'
import SplitPane from './SplitPane.css'
import SplitPaneSupport from './SplitPaneSupport'
import TabPane from './TabPane.css'
import TabPaneSupport from './TabPaneSupport'
import Table from './Table.css'
import TableSupport from './TableSupport'
import TableV2 from './TableV2.css'
import TableV2Support from './TableV2Support'
import Text from './Text.css'
import TextSupport from './TextSupport'
import TextArea from './TextArea.css'
import TextAreaSupport from './TextAreaSupport'
import TextInput from './TextInput.css'
import TextInputSupport from './TextInputSupport'
import Toast from './Toast.css'
import ToastSupport from './ToastSupport'
import Toggle from './Toggle.css'
import ToggleSupport from './ToggleSupport'
import ToggleButton from './ToggleButton.css'
import ToggleButtonSupport from './ToggleButtonSupport'
import VTable from './VTable.css'
import VTableSupport from './VTableSupport'


const styles = {
  Body,
  Breadcrumbs,
  Button,
  Checkbox,
  Combobox,
  DataBlock,
  DateTimePicker,
  DateTimeRangePicker,
  Dropdown,
  Flex,
  Label,
  List,
  MultiSelect,
  Pane,
  Popup,
  Portal,
  RadioButton,
  Slider,
  SplitPane,
  TabPane,
  Table,
  TableV2,
  Text,
  TextArea,
  TextInput,
  Toast,
  Toggle,
  ToggleButton,
  VTable
}
const supports = {
  Body : BodySupport,
  Breadcrumbs : BreadcrumbsSupport,
  Button : ButtonSupport,
  Checkbox : CheckboxSupport,
  Combobox : ComboboxSupport,
  DataBlock : DataBlockSupport,
  DateTimePicker : DateTimePickerSupport,
  DateTimeRangePicker : DateTimeRangePickerSupport,
  Dropdown : DropdownSupport,
  Element : ElementSupport,
  Flex : FlexSupport,
  Label : LabelSupport,
  List : ListSupport,
  MultiSelect : MultiSelectSupport,
  Pane : PaneSupport,
  Popup : PopupSupport,
  Portal : PortalSupport,
  RadioButton : RadioButtonSupport,
  Slider : SliderSupport,
  SplitPane : SplitPaneSupport,
  TabPane : TabPaneSupport,
  Table : TableSupport,
  TableV2 : TableV2Support,
  Text : TextSupport,
  TextArea : TextAreaSupport,
  TextInput : TextInputSupport,
  Toast : ToastSupport,
  Toggle : ToggleSupport,
  ToggleButton : ToggleButtonSupport,
  VTable : VTableSupport
}
module.exports = { styles, supports }