module.exports = {
  "hierarchy": {
    "title": [
      "text-title"
    ],
    "subtitle": [
      "text-subtitle"
    ],
    "body": [
      "text-body"
    ],
    "bodySmall": [
      "text-bodySmall"
    ],
    "caption": [
      "text-caption"
    ],
    "captionSmall": [
      "text-captionSmall"
    ],
    "navLogoLabel": [
      "text-subtitle",
      "text-navLogoLabel"
    ],
    "pageTitle": [
      "text-title",
      "text-pageTitle"
    ],
    "sectionTitle": [
      "text-title",
      "text-sectionTitle"
    ],
    "sectionBody": [
      "text-bodySmall",
      "text-sectionBody"
    ],
    "cardTitle": [
      "text-subtitle",
      "text-cardTitle"
    ],
    "sidebarTitle": [
      "text-title",
      "text-sidebarTitle"
    ],
    "formTitle": [
      "text-title",
      "text-formTitle"
    ],
    "formSubtitle": [
      "text-subtitle",
      "text-formSubtitle"
    ],
    "formBody": [
      "text-body",
      "text-formBody"
    ],
    "listItemTitle": [
      "text-subtitle",
      "text-listItemTitle"
    ],
    "listItemSubtitle": [
      "text-caption",
      "text-listItemSubtitle"
    ],
    "inputCaptionSmall": [
      "text-captionSmall",
      "text-inputCaptionSmall"
    ],
    "inputCaptionSmallError": [
      "text-captionSmall",
      "text-inputCaptionSmall",
      "text-inputCaptionSmallError"
    ],
    "loginWarning": [
      "text-bodySmall",
      "text-loginWarning"
    ],
    "sidebarTitleMobile": [
      "text-title",
      "text-sidebarTitle",
      "text-sidebarTitleMobile"
    ],
    "mobileMapTitle": [
      "text-bodySmall",
      "text-mobileMapTitle"
    ],
    "mobileTitle": [
      "text-bodySmall",
      "text-mobileTitle"
    ]
  },
  "props": {
    "default": {},
    "title": {},
    "subtitle": {},
    "body": {},
    "bodySmall": {},
    "caption": {},
    "captionSmall": {},
    "navLogoLabel": {},
    "pageTitle": {},
    "sectionTitle": {},
    "sectionBody": {},
    "cardTitle": {},
    "sidebarTitle": {},
    "formTitle": {},
    "formSubtitle": {},
    "formBody": {},
    "listItemTitle": {},
    "listItemSubtitle": {},
    "inputCaptionSmall": {},
    "inputCaptionSmallError": {},
    "loginWarning": {},
    "sidebarTitleMobile": {},
    "mobileMapTitle": {},
    "mobileTitle": {}
  }
}