/* eslint-disable import/prefer-default-export */
export function calculator( model, activeRole ) {
  const { single, multiple, resourceType } = model
  const { userRoles } = activeRole

  const roles = userRoles.filter( role => role.resourceType === resourceType )

  if ( roles.length === 0 ) {
    return multiple
  }

  if ( single && roles.length === 1 ) {
    if ( single.indexOf( ':resourceId' ) === -1 ) {
      console.log( 'InitialUrlCalculator: single url template does not contain :resourceId token' )

      return single
    }

    return single.replace( ':resourceId', roles[0].resourceId )
  }

  if ( roles.length > 1 ) {
    console.log( `InitialUrlCalculator: More than one user role specified for resource type ${resourceType}` )

    return null
  }

  return null
}
