import { Interface } from '@leverege/api-redux'

import Util from './Util'

import HolsterActions from './HolsterActions'

import OfficerActions from './OfficerActions'

import ZoneActions from './ZoneActions'


function doFlatten( obj ) {
  const r = [ obj ]
  let p = obj.parent
  while ( p ) {
    r.unshift( p )
    p = p.parent
  }
  return r
}

class PrecinctList {
  constructor( { rootActions, parent, tabId, systemId = process.env.systemId, alias = 'precinct' } ) {
    this.rootActions = rootActions || Interface.create( systemId, alias )
    this.systemId = systemId
    this.alias = alias
    this.parent = parent
    this.isCollection = true
    this.tabId = tabId
    this.dataName = 'Precinct'
  }
  
  getPath() {
    return this.rootActions && this.rootActions.path
  }

  get( id ) {
    return this.rootActions.get( id )
  }

  list( pagination = { perPage : 500 } ) {
    return this.rootActions.list( pagination )
  }

  create( ...args ) {
    return this.rootActions.create( ...args )
  }

  delete( ...args ) {
    return this.rootActions.delete( ...args )
  }

  update( id, data, options ) {
    return this.rootActions.update( id, data, options )
  }

  model( ...args ) {
    return this.rootActions.model( ...args )
  }

  filter( ...args ) {
    return this.rootActions.filter( ...args )
  }

  precinct( id ) {
    return new Precinct(
      id,
      { 
        rootActions : this.rootActions.obj( id ), 
        parent : this.parent, 
        tabId : this.tabId, 
        systemId : process.env.systemId
      }
    )
  }

  
  holsters( id, { precinctId = '*', ...options } ) {
    if ( id ) {
      return HolsterActions.create( id, { 
        ...options,
        parent : this,
        rootActions : this.rootActions.obj( precinctId ).grp( 'holsters' ).obj( id ),
        containingList : HolsterActions.create( null, {
          ...options, 
          parent : this,
          rootActions : this.rootActions.obj( precinctId ).grp( 'holsters' ) 
        } ),
      } )
    }
    return HolsterActions.create( null, {
      ...options, 
      parent : this,
      rootActions : this.rootActions.obj( precinctId ).grp( 'holsters' ) 
    } )
  }
  
  officers( id, { precinctId = '*', ...options } ) {
    if ( id ) {
      return OfficerActions.create( id, { 
        ...options,
        parent : this,
        rootActions : this.rootActions.obj( precinctId ).grp( 'officers' ).obj( id ),
        containingList : OfficerActions.create( null, {
          ...options, 
          parent : this,
          rootActions : this.rootActions.obj( precinctId ).grp( 'officers' ) 
        } ),
      } )
    }
    return OfficerActions.create( null, {
      ...options, 
      parent : this,
      rootActions : this.rootActions.obj( precinctId ).grp( 'officers' ) 
    } )
  }
  
  zones( id, { precinctId = '*', ...options } ) {
    if ( id ) {
      return ZoneActions.create( id, { 
        ...options,
        parent : this,
        rootActions : this.rootActions.obj( precinctId ).grp( 'zones' ).obj( id ),
        containingList : ZoneActions.create( null, {
          ...options, 
          parent : this,
          rootActions : this.rootActions.obj( precinctId ).grp( 'zones' ) 
        } ),
      } )
    }
    return ZoneActions.create( null, {
      ...options, 
      parent : this,
      rootActions : this.rootActions.obj( precinctId ).grp( 'zones' ) 
    } )
  }
  

  /** 
   * Returns an array the parent chain. Element zero will be the rootPath
   **/ 
  flatten( ) { return doFlatten( this ) }

}

class Precinct {
  constructor( id, { rootActions,containingList, parent, tabId, systemId = process.env.systemId, alias = 'precinct', toOneRelationship } ) {
    this.rootActions = rootActions || Interface.create( systemId, alias ).obj( id )
    this.containingList = containingList
    this.systemId = systemId
    this.alias = alias
    this.id = id
    this.parent = parent
    this.isCollection = false
    this.tabId = tabId
    this.toOneRelationship = toOneRelationship
    this.dataName = 'Precinct'
  }

  getPath() {
    return this.rootActions && this.rootActions.path
  }

  get( ...args ) {
    return this.rootActions.get( ...args )
  }

  // needed for * queries
  list( { perPage = 500, ...rest } ) {
    return this.rootActions.list( { perPage, ...rest } )
  }

  history( ...args ) {
    return this.rootActions.history( ...args )
  }

  create( ...args ) {
    return this.rootActions.create( ...args )
  }

  delete( ...args ) {
    return this.rootActions.delete( ...args )
  }

  update( data, options ) {
    return this.rootActions.update( data, options )
  }

  model( ...args ) {
    return this.rootActions.model( ...args )
  }

  filter( ...args ) {
    return this.rootActions.filter( ...args )
  }

  user( ...args ) {
    return this.rootActions.user( ...args )
  }

  users( ...args ) {
    return this.rootActions.users( ...args )
  }


  holsters( id, options ) {
    if ( id ) {
      return HolsterActions.create( id, { 
        ...options,
        parent : this, 
        rootActions : this.rootActions.grp( 'holsters' ).obj( id ),
        containingList : HolsterActions.create( null, {
          ...options, 
          parent : this, 
          rootActions : this.rootActions.grp( 'holsters' ) 
        } )
      } )
    }
    return HolsterActions.create( null, {
      ...options, 
      parent : this, 
      rootActions : this.rootActions.grp( 'holsters' ) 
    } )
  }

  officers( id, options ) {
    if ( id ) {
      return OfficerActions.create( id, { 
        ...options,
        parent : this, 
        rootActions : this.rootActions.grp( 'officers' ).obj( id ),
        containingList : OfficerActions.create( null, {
          ...options, 
          parent : this, 
          rootActions : this.rootActions.grp( 'officers' ) 
        } )
      } )
    }
    return OfficerActions.create( null, {
      ...options, 
      parent : this, 
      rootActions : this.rootActions.grp( 'officers' ) 
    } )
  }

  zones( id, options ) {
    if ( id ) {
      return ZoneActions.create( id, { 
        ...options,
        parent : this, 
        rootActions : this.rootActions.grp( 'zones' ).obj( id ),
        containingList : ZoneActions.create( null, {
          ...options, 
          parent : this, 
          rootActions : this.rootActions.grp( 'zones' ) 
        } )
      } )
    }
    return ZoneActions.create( null, {
      ...options, 
      parent : this, 
      rootActions : this.rootActions.grp( 'zones' ) 
    } )
  }

  /** 
   * Returns an array the parent chain. Element zero will be the rootPath
   **/ 
  flatten( ) { return doFlatten( this ) }
}

function create( id, options = { } ) {
  let rootActions = null
  const { rootPath, singular } = options
  if ( rootPath ) {
    rootActions = Util.buildActions( { ...rootPath, finalType : 'precinct' } )
  }

  if ( singular || id ) {
    const containingListActions = ( ( rootActions || options.rootActions ) && ( rootActions || options.rootActions ).parent ) || null
    return new Precinct(
      id,
      {
        ...options,
        rootActions : rootActions || options.rootActions,
        containingList : options.containingList || create( null, { ...options, rootActions : containingListActions, singular : false } )
      }
    )
  }
  return new PrecinctList( { ...options, rootActions : rootActions || options.rootActions } )
}

exports.create = create
exports.PrecinctList = PrecinctList
exports.Precinct = Precinct
