import OfficerActions from './OfficerActions'
import PrecinctActions from './PrecinctActions'
import HolsterActions from './HolsterActions'
import ZoneActions from './ZoneActions'


const registry = {
  officer : OfficerActions,
  precinct : PrecinctActions,
  holster : HolsterActions,
  zone : ZoneActions,
}

function buildActions( { route, alias, finalType } ) {

  let actions = registry[alias]
  if ( !route || route.length === 0 || !actions ) {
    return null
  }
  route.forEach( ( { path, id } ) => {
    actions = actions[path]( id )
  } )

  if ( !( actions instanceof registry[finalType] ) ) {
    return null
  }
  return actions.rootActions
}

exports.buildActions = buildActions
