import React from 'react'
import Loadable from 'react-loadable'
import LoadingScreen from './components/shared/view/LoadingScreen'

const Desktop = Loadable( {
  loader : () => import( './components/desktop/App' ).catch( console.log ),
  loading() {
    return <LoadingScreen />
  }
} )

const Mobile = Loadable( {
  loader : () => import( './components/mobile/App' ),
  loading() {
    return <LoadingScreen />
  }
} );

export default class BootApp extends React.Component {
  
  constructor( props ) {
    super( props )
    this.state = { isMobile : this.isMobile() }
  }

  onResize = () => {
    this.setState( { isMobile : this.isMobile() } )
  }

  componentDidMount() {
    window.addEventListener( 'resize', this.onResize )
  }

  componentWillUnmount() {
    window.removeEventListener( 'resize', this.onResize )
  }

  isMobile( ) {
    const wWidth = window.innerWidth;
    return wWidth < 1024
  }

  render() {
    if ( this.state.isMobile ) { // eslint-disable-line react/destructuring-assignment
      return <Mobile />
    } 
    return <Desktop />
  }
}
