import Path from '@leverege/path'
import { Plugins } from '@leverege/plugin'
import { GlobalState } from '@leverege/ui-redux'

// import Attributes from '../attribute/Attributes'

/**
 * Given a Blueprint, this will create attributes for each blueprint attribute
 */

export default class Blueprint {

  constructor( model, options = { } ) {
    const { useAliases = true } = options
    const { id, alias } = model
    
    this.id = id
    this.type = ( useAliases && alias ) ? alias : id
    this.model = { ...model, attributes : [ ...model.attributes ] }
    this.options = options
    this.namedAttributes = { 
      name : null,
      icon : null,
      geoPosition : null,
      possName : null,
      possGeoPosition : null
    }
    this.attributes = {
      hidden : [],
      local : [],
      child : [],
      parent : []
    }

    this.itemize()
    
    // console.log( this.type, this.namedAttributes, this.attributes )
  }

  /**
   * Returns the tags in the model
   */
  get tags() {
    return this.model.tags || []
  }

  /**
   * This looks at the attributes and puts them into groups of not shown, 
   * local, child and parent (1-N). This leaves the attributes as
   * they are (api-attributes)
   */
  itemize() {
    const { hidden, local, child, parent, forwardData } = this.attributes
    const { roles = [ 'user' ], networks = [] } = this.options
    const { attributes } = this.model
    const excludeFrom = roles ? roles.map( role => `excludeFrom:${role}` ) : []
    

    // const iconAttr = this.getIconAttr( key )
    // if ( iconAttr ) {
    //   this.setNamedAttribute( 'icon', iconAttr )
    // }

    for ( let n = 0; n < attributes.length; n++ ) {
      const attr = attributes[n]
      const { path, type, isCollection, relationshipType, forwardData } = attr
      const tags = attr.tags || [] 

      if ( isCollection ) {
        // skipping collections for now
        hidden.push( attr )
      } else if ( tags.includes( 'is:hidden' ) || tags.some( tag => excludeFrom.includes( tag ) ) ) {
        // old attribute perhaps - or techy field - dont include it
        hidden.push( attr )
      } else if ( type === 'relationship' && relationshipType === 'oneToOne' ) {
        if ( forwardData ) {
          child.push( attr )
        } else {
          hidden.push( attr ) // maybe put this into parent
        }
      } else if ( type === 'parentRelationship' ) {
        // reference to parent. we can add some local attributes
        parent.push( attr )
      } else {
        const isName = type === 'string' && tags.includes( 'is:name' )
        const isGeoPosition = type === 'geoPoint' && tags.includes( 'is:geoPosition' )
        local.push( attr )

        if ( isName ) {
          this.setNamedAttribute( 'name', attr ) 
        } else if ( path === 'name' && type === 'string' ) {
          this.setNamedAttribute( 'possName', attr ) 
        }
        if ( isGeoPosition ) { 
          this.setNamedAttribute( 'geoPosition', attr ) 
        } else if ( path === 'position' && type === 'geoPoint' ) { 
          this.setNamedAttribute( 'possGeoPosition', attr ) 
        }
      }
    }
  }

  setNamedAttribute( name, attr ) {
    if ( this.namedAttributes[name] ) {
      // eslint-disable-next-line no-console
      // console.log( `Warning, attribute \'${name}\' has already been specified`, nameAttr, attr )
    } else {
      this.namedAttributes[name] = attr
    }
  }

  getDataSource() {
    if ( this.dataSource == null ) {
      const name = this.model.name
      this.dataSource = {
        getTypeName : ( type ) => {
          return name
        },
        getData : ( objRef ) => {
          return GlobalState.get().models.lookup[objRef.id]
        }
      }
    }
    return this.dataSource
  }

  getIconAttr( type ) {
    const icon = this.model?.metadata?.icon
    if ( icon ) {
      return {
        name : 'icon',
        displayName : 'Icon',
        objectType : type,
        valueType : 'string', // should it be 'icon'?
        get : ( ) => { return icon }
      }
    }
    return null
  }

  calculatePathLengths( blueprints ) {
    // if ( this.pathLengths ) { 
    //   return this.pathLenghts
    // }
    // if ( this.attributes.child.length === 0 ) {
    //   this.
    // })
  }
}
