// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".popup_wJdWw {\n  color: var(--color-base800);\n  border: 1px solid var(--color-base100);\n  font-size: var(--size-bodyMedium);\n  box-shadow: 0 8px 16px var(--color-shadowBase), 0 0px 1px var(--color-shadowBase);\n  border-radius: 4px;\n  background-color: var(--color-white);\n}\n.popup_wJdWw > .popupItem_gf3uM {\n  font: normal 15px/24px var(--vf-Inter);\n  color: var(--color-base700);\n  width: 100%;\n  padding: var(--size-padMedium) var(--size-padLarge);\n  display: block;\n  min-width: 160px;\n  text-align: left;\n  min-height: 40px;\n}\n.popup_wJdWw > .popupItem_gf3uM:hover {\n  background-color: var(--color-base050);\n}\n.popup_wJdWw > .popupItem_gf3uM:active {\n  color: var(--color-brand500);\n}\n.popup_wJdWw > .popupItem_gf3uM[disabled] {\n  color: var(--color-base300);\n}\n.popup_wJdWw > .popupDivider_30hSQ {\n  height: 1px;\n  background-color: var(--color-base100);\n}\n.popup_wJdWw.popup-mainScreenMode_2BtMK {\n  padding: var(--size-padSmall);\n  margin-top: var(--size-padSmall);\n}\n.popup_wJdWw.popup-mainScreenMode_2BtMK > .popupItem_gf3uM {\n  color: var(--color-base300);\n  font-size: var(--size-header4);\n  min-width: 0px;\n  border-radius: var(--size-small);\n}\n.popup_wJdWw.popup-mainScreenMode_2BtMK > .popupItem_gf3uM:hover {\n  color: var(--color-brand500);\n  background-color: var(--color-brand100);\n}\n.popup_wJdWw.popup-mainScreenMode_2BtMK > .popupItem_gf3uM:active {\n  color: var(--color-brand500);\n}\n", ""]);
// Exports
exports.locals = {
	"popup": "popup_wJdWw",
	"popupItem": "popupItem_gf3uM",
	"popupDivider": "popupDivider_30hSQ",
	"popup-mainScreenMode": "popup-mainScreenMode_2BtMK"
};
module.exports = exports;
