import PatchOps from '@leverege/api-patch-ops'
import { Auth, Project } from '@leverege/api-redux'
import Constants from './Constants'

function get( systemId ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.SYSTEM_LOADING, value : true } )
    try {
      
      const res = await api
        .system( process.env.systemId )
        .get()

      dispatch( { type : Constants.SYSTEM_LOADED, metadata : res.metadata || {} } )
    } catch ( err ) {
      handleError( {
        err,
        dispatch,
        message : 'Error fetching system'
      } )
    }
  }
}

function updateMetadata( metadata ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.SYSTEM_UPDATING, value : true } )
    try {
      const res = await api
        .system( process.env.systemId )
        .update( { metadata } )

      return dispatch( { type : Constants.SYSTEM_UPDATED, metadata : res.metadata || {} } )
    } catch ( err ) {
      handleError( {
        err,
        dispatch,
        message : 'Error updating system'
      } )

      return null
    }
  }
}


function addEnum( enumType, enumName ) {
  return async ( dispatch, getState, { api } ) => {
    dispatch( { type : Constants.SYSTEM_UPDATING, value : true } )
    try {
      const res = await api
        .system( process.env.systemId )
        .update( [ { path : `metadata/enums/${enumType}/${enumName}`, value : true, op : 'replace' } ] )
      dispatch( { type : Constants.SYSTEM_UPDATED, metadata : res.metadata || {} } )
      return true
    } catch ( err ) {
      handleError( {
        err,
        dispatch,
        message : 'Error updating system'
      } )

      return false
    }
  }
}

function handleError( opts ) {
  const { err } = opts
  if ( err.status === 403 ) {
    const { dispatch } = opts
    dispatch( Auth.logout() )
  } else {
    const { callback, message } = opts
    const msg = message || ''
    console.error( msg, err )
    if ( callback && typeof callback === 'function' ) {
      callback()
    }
  }
}

exports.get = get
exports.updateMetadata = updateMetadata
exports.addEnum = addEnum
