// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".toast_Hkj2S {\n  font: normal 15px/24px var(--vf-Inter);\n  color: var(--color-base700);\n  border: 1px solid var(--color-base100);\n  box-shadow: 0 4px 8px var(--color-shadowBase);\n  padding-left: var(--size-padLarge);\n  border-radius: 4px;\n  padding-right: var(--size-padMedium);\n}\n.toast_Hkj2S > .Toastify__progress-bar {\n  background: none;\n}\n.toast_Hkj2S.toast-success_1blra {\n  border: 1px solid var(--color-success100);\n  background: var(--color-white);\n}\n.toast_Hkj2S.toast-error_l0tpI {\n  border: 1px solid var(--color-danger100);\n  background: var(--color-white);\n}\n.toast_Hkj2S.toast-warn_vJ7RU {\n  border: 1px solid var(--color-warning100);\n  background: var(--color-white);\n}\n", ""]);
// Exports
exports.locals = {
	"toast": "toast_Hkj2S",
	"toast-success": "toast-success_1blra",
	"toast-error": "toast-error_l0tpI",
	"toast-warn": "toast-warn_vJ7RU"
};
module.exports = exports;
