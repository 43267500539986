module.exports = {
  "hierarchy": {
    "primary": [
      "button-primary"
    ],
    "secondary": [
      "button-secondary"
    ],
    "tertiary": [
      "button-tertiary"
    ],
    "primaryIconic": [
      "button-primary",
      "button-primaryIconic"
    ],
    "secondaryIconic": [
      "button-secondary",
      "button-secondaryIconic"
    ],
    "tertiaryIconic": [
      "button-tertiary",
      "button-tertiaryIconic"
    ],
    "primarySmall": [
      "button-primary",
      "button-primarySmall"
    ],
    "secondarySmall": [
      "button-secondary",
      "button-secondarySmall"
    ],
    "tertiarySmall": [
      "button-tertiary",
      "button-tertiarySmall"
    ],
    "primarySmallIconic": [
      "button-primary",
      "button-primaryIconic",
      "button-primarySmallIconic"
    ],
    "secondarySmallIconic": [
      "button-secondary",
      "button-secondaryIconic",
      "button-secondarySmallIconic"
    ],
    "tertiarySmallIconic": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic"
    ],
    "primaryTinyIconic": [
      "button-primary",
      "button-primaryIconic",
      "button-primarySmallIconic",
      "button-primaryTinyIconic"
    ],
    "secondaryTinyIconic": [
      "button-secondary",
      "button-secondaryIconic",
      "button-secondarySmallIconic",
      "button-secondaryTinyIconic"
    ],
    "tertiaryTinyIconic": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-tertiaryTinyIconic"
    ],
    "link": [
      "button-link"
    ],
    "linkDestructive": [
      "button-link",
      "button-linkDestructive"
    ],
    "linkTable": [
      "button-link",
      "button-linkTable"
    ],
    "primaryDestructive": [
      "button-primary",
      "button-primaryDestructive"
    ],
    "primaryCancel": [
      "button-tertiary",
      "button-primaryCancel"
    ],
    "desktopSearch": [
      "button-desktopSearch"
    ],
    "tableColumnAdd": [
      "button-secondary",
      "button-tableColumnAdd"
    ],
    "tableColumnRemove": [
      "button-primary",
      "button-primaryIconic",
      "button-primarySmallIconic",
      "button-primaryTinyIconic",
      "button-tableColumnRemove"
    ],
    "closeWindow": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-closeWindow"
    ],
    "navLogo": [
      "button-navLogo"
    ],
    "navRefresh": [
      "button-navRefresh"
    ],
    "pagination": [
      "button-secondary",
      "button-secondaryIconic",
      "button-secondarySmallIconic",
      "button-pagination"
    ],
    "mobileAction": [
      "button-tertiary",
      "button-mobileAction"
    ],
    "mobileNavIcon": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-mobileNavIcon"
    ],
    "mobileNavTitle": [
      "button-tertiary",
      "button-mobileNavTitle"
    ],
    "closeToast": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-closeToast"
    ],
    "mapControl": [
      "button-primary",
      "button-primaryIconic",
      "button-primarySmallIconic",
      "button-primaryTinyIconic",
      "button-mapControl"
    ],
    "zoomControl": [
      "button-primary",
      "button-primaryIconic",
      "button-primarySmallIconic",
      "button-primaryTinyIconic",
      "button-mapControl",
      "button-zoomControl"
    ],
    "clearSearch": [
      "button-tertiary",
      "button-tertiaryIconic",
      "button-tertiarySmallIconic",
      "button-tertiaryTinyIconic",
      "button-clearSearch"
    ]
  },
  "props": {
    "default": {},
    "primary": {},
    "secondary": {},
    "tertiary": {},
    "primaryIconic": {},
    "secondaryIconic": {},
    "tertiaryIconic": {},
    "primarySmall": {},
    "secondarySmall": {},
    "tertiarySmall": {},
    "primarySmallIconic": {},
    "secondarySmallIconic": {},
    "tertiarySmallIconic": {},
    "primaryTinyIconic": {},
    "secondaryTinyIconic": {},
    "tertiaryTinyIconic": {},
    "link": {},
    "linkDestructive": {},
    "linkTable": {},
    "primaryDestructive": {},
    "primaryCancel": {},
    "desktopSearch": {},
    "tableColumnAdd": {
      "icon": ".lv-plus"
    },
    "tableColumnRemove": {},
    "closeWindow": {},
    "navLogo": {},
    "navRefresh": {},
    "pagination": {},
    "mobileAction": {},
    "mobileNavIcon": {},
    "mobileNavTitle": {},
    "closeToast": {},
    "mapControl": {},
    "zoomControl": {},
    "clearSearch": {}
  }
}