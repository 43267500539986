

import { Interface, Constants } from '@leverege/api-redux'
import Util from './Util'

import Holsters from './Holsters'


function doFlatten( obj ) {
  const r = [ obj ]
  let p = obj.parent
  while ( p ) {
    r.unshift( p )
    p = p.parent
  }
  return r
}

class OfficerList {
  constructor( { modelInterface, parent, tabId, name, systemId = process.env.systemId, alias = 'officer' } ) {
    this.modelInterface = modelInterface || Interface.create( systemId, alias )
    this.systemId = systemId
    this.alias = alias
    this.parent = parent
    this.listName = name
    this.isCollection = true
    this.tabId = tabId
    this.dataName = 'Officer'
    this.dataNamePlural = 'Officers'
  }

  name( ) {
    return this.listName
  }

  url() {
    // for now, just parse it from modelInterface
    const path = this.modelInterface.path
    const idx = path == null ? -1 : path.indexOf( '/' )
    return idx < 0 ? '/' : `${path.substring( idx )}${this.id == null ? '' : `/${this.id}`}`
  }

  list( state, opts ) {
    return this.modelInterface.getList( state.models, opts )
  }

  table( state, options ) {
    return this.modelInterface.getTable( state.models, options )
  }

  model( state ) {
    return this.modelInterface.getModel( state.models )
  }

  history( state, queryName ) {
    return this.modelInterface.getHistory( state.models, queryName )
  }

  historyIsLoading( state, queryName ) {
    return state.models.data[`${this.modelInterface.path}.history/${queryName}`] && state.models.data[`${this.modelInterface.path}.history/${queryName}`].status === Constants.INTERFACE_STATUS_LOADING
  }

  isTable( state, opts ) {
    return this.modelInterface.isTable( state.models, opts )
  }

  getModel( state ) {
    return this.modelInterface.getModel( state.models )
  }

  get( state, id, opts ) {
    const Officer = this.modelInterface.getList( state.models, opts )
    return Officer && Officer.items.find( item => item.id === id )
  }

  isLoading( state ) {
    return this.modelInterface.isLoading( state.models )
  }

  isError( state ) {
    return this.modelInterface.isError( state.models )
  }

  isDone( state ) {
    return this.modelInterface.isDone( state.models )
  }

  officer( id ) {
    return new Officer(
      id,
      { 
        modelInterface : this.modelInterface.obj( id ), 
        parent : this.parent, 
        tabId : this.tabId, 
        systemId : process.env.systemId
      }
    )
  }

  
  holster( id, { officerId = '*', ...options } ) {
      return Holsters.create( id, { 
        ...options,
        modelInterface : this.modelInterface.obj( officerId ).obj( 'holster' ), 
        singular : true 
      } )
  }
  

  /** 
   * Returns an array the parent chain. Element zero will be the rootPath
   **/ 
  flatten( ) { return doFlatten( this ) }
}

class Officer {
  constructor( id, { modelInterface, containingList, parent, tabId, systemId = process.env.systemId, alias = 'officer', toOneRelationship = false } ) {
    this.modelInterface = modelInterface || Interface.create( systemId, alias ).obj( id )
    this.systemId = systemId
    this.alias = alias
    this.id = id
    this.parent = parent
    this.containingList = containingList
    this.isCollection = false
    this.toOneRelationship = toOneRelationship
    this.tabId = tabId
    this.dataName = 'Officer'
    this.dataNamePlural = 'Officers'
  }

  url() {
    // for now, just parse it from modelInterface
    const path = this.modelInterface.parentPath
    const idx = path == null ? -1 : path.indexOf( '/' )
    if ( this.id ) {
      return this.tabId == null ? 
        `${path.substring( idx )}/${this.id}` : 
        `${path.substring( idx )}/${this.id}/${this.tabId}`
    }
    return idx < 0 ? '/' : `${path.substring( idx )}`
  }

  name( state, dValue ) {
    const data = this.get( state )
    // TODO: Need to get attribute that represents the name
        return ( data && data.data && data.data?.lastName ) || dValue || this.id
  }

  get( state ) {
    return this.modelInterface.getObject( state.models, this.id )
  }

  table( state, options ) {
    return this.modelInterface.getTable( state.models, options )
  }

  model( state ) {
    return this.modelInterface.getModel( state.models )
  }

  history( state, queryName ) {
    return this.modelInterface.getHistory( state.models, queryName )
  }

  historyIsLoading( state, queryName ) {
    return state.models.data[`${this.modelInterface.path}.history/${queryName}`] && state.models.data[`${this.modelInterface.path}.history/${queryName}`].status === Constants.INTERFACE_STATUS_LOADING
  }

  isTable( state ) {
    return this.modelInterface.isTable( state.models )
  }

  isLoading( state ) {
    return this.modelInterface.isLoading( state.models )
  }

  isError( state ) {
    return this.modelInterface.isError( state.models )
  }

  isDone( state ) {
    return this.modelInterface.isDone( state.models )
  }

  holster( options ) {
      return Holsters.create( 'holster', { 
        ...options,
        parent : this,
        name : 'Assigned Holster',
        modelInterface : this.modelInterface.obj( 'holster' ), 
        singular : true,
        toOneRelationship : true } )

  }

  
  /** 
   * Returns an array the parent chain. Element zero will be the rootPath
   **/ 
  flatten( ) { return doFlatten( this ) }

}

function create( id, options = { } ) {
  let modelInterface = null
  const { rootPath, singular } = options
  if ( rootPath ) {
    modelInterface = Util.buildAccessors( { ...rootPath, finalType : 'officer' } )
  }

  if ( singular || id ) {
    const containingModelInterface = ( ( modelInterface || options.modelInterface ) && ( modelInterface || options.modelInterface ).parent ) || null
    return new Officer(
      id,
      {
        name : 'Officers',
        ...options,
        modelInterface : modelInterface || options.modelInterface,
        containingList : options.containingList || create( null, { ...options, modelInterface : containingModelInterface, singular : false } )
      }
    )
  }
  return new OfficerList( { name : 'Officers', ...options, modelInterface : modelInterface || options.modelInterface } )
}

exports.create = create
exports.OfficerList = OfficerList
exports.Officer = Officer

