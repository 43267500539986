import React from 'react'
import Thunk from 'redux-thunk'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import Api from '@leverege/api'
import { ApiProvider, ApiUserSettingsDelegate } from '@leverege/api-redux'
import { GlobalState, UserSettings, UI } from '@leverege/ui-redux'
import { DynamicStore } from '@leverege/ui-plugin'
import { Theme } from '@leverege/ui-elements'
import { PluginSetup } from '@leverege/ui-attributes'
import ActiveTheme from '@leverege/ui-active-theme'

import { PluginSetup as LocalPluginSetup } from './plugins'

import './css/global-style.css'
import AppTheme from './theme'
import BootApp from './BootApp'
import reducers from './reducers'

import { Select, Auth, Constants, SystemActions, Startup } from './actions'

function start( opts = { } ) {
  PluginSetup.install()
  LocalPluginSetup.install()

  opts.storeToken = true // eslint-disable-line no-param-reassign
  const api = Api.init( opts.api || {
    host : process.env.host,
    projectId : opts.projectId || process.env.projectId,
    storeToken : true
  } )

  const history = createBrowserHistory( {
    basename : '/'
  } )
  const dynamicStore = new DynamicStore( {
    reducers : reducers( { history } ),
    middleware : [
      Thunk.withExtraArgument( { api } ),
      routerMiddleware( history ),
      ( { dispatch } ) => next => async ( action ) => {
        const returnValue = next( action );
        if ( action.type === Constants.AUTH_LOGGED_IN ) {
          const roles = await api.project( process.env.projectId ).account( action.profile.id ).roles( { format : 'uniqueRoles' } )
          const profiles = roles?.items?.filter( r => r?.scope === 'custom' ) || []
          const profile = profiles[0]?.name || 'default'
          UserSettings.setDelegate( new ApiUserSettingsDelegate( {
            fallback : [ { ownerId : action.profile.id, ownerType : 'user' }, { ownerId : process.env.systemId, ownerType : 'system' } ],
            dispatch,
            profile
          } ) )

          dispatch( UI.multiSet( {
            userRoles : profiles,
            activeRole : profiles[0] || { name : 'default' }
          } ) )
          dispatch( Startup.run() )
        } else if ( action.type === Constants.AUTH_LOGGED_OUT ) {
          setTimeout( () => {
            dispatch( Select.clearAll( ) )
            dispatch( UI.multiSet( {
              userRoles : null,
              activeRole : null
            } ) )
          }, 1 )
        }
        return returnValue;
      } ]
  } )
  const store = dynamicStore.create()

  GlobalState.attachStore( store )
  store.dispatch( Auth.verify() )

  let theme = AppTheme
  // if ( process.env.NODE_ENV === 'development' ) {
  //   theme = new ActiveTheme( { altTheme : AppTheme, appearanceProjectId : '4kDqAFztZC5pThA2U39dN0' } )
  // } else {
  //   theme = AppTheme
  // }
  window.store = store

  ReactDOM.render(
    <ApiProvider api={api}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Theme theme={theme}>
            <BootApp />
          </Theme>
        </ConnectedRouter>
      </Provider>
    </ApiProvider>,
    document.getElementById( 'root' ) )
}

window.Application = { start }
