import { Project } from '@leverege/api-redux'
import { Attributes, Details, RolloverCard, RolloverModel, RolloverEditor,
  SelectionCard, SelectionModel, SelectionEditor, TableModel } from '@leverege/ui-attributes'
import { GlobalState, UI, UserSettings } from '@leverege/ui-redux'

import { Plugins } from '@leverege/plugin'

import BlueprintAnalyzer from './BlueprintAnalyzer'

function run( ) {
  return async ( dispatch, getState, { api } ) => {

    // dispatch( Project( ).networks().list() )
    // console.log( '---Networks----' )
    let networks = { }
    try {
      networks = await api.project( process.env.projectId ).networks().list()
      // console.log( 'networks', networks )
    } catch ( ex ) { 
      // console.log( ex )
    }
    // console.log( '---Blueprints----' )

    const blueprints = await dispatch( Project( ).blueprints().list() )
    processBlueprints( blueprints, networks.items || [] )
    // console.log( '---Roles----' )

    const roles = await dispatch( Project( ).roles().list( { scope : 'custom' } ) )
    // console.log( 'roles', roles )

    // TODO if any of above fail, we need to go to clear redux an error login screen
  }
}

// move this out of here
async function processBlueprints( bps, networks ) {
  const ba = new BlueprintAnalyzer( bps.items, { networks } )
  ba.install()
  
  // Temp until we can save.
  const dataSources = Plugins.get( 'DataSource' )
  let rolloverModel = RolloverModel.create()
  let selectionModel = SelectionModel.create()
  
  // console.log( 'Ds=', dataSources )
  dataSources.forEach( ( ds ) => {
    rolloverModel = RolloverModel.addType( rolloverModel, ds.type )
    selectionModel = SelectionModel.addType( selectionModel, ds.type )
  } )

  GlobalState.dispatch( UI.set( 'defaultRolloverModel', rolloverModel ) )
  GlobalState.dispatch( UI.set( 'defaultSelectionModel', selectionModel ) )

  // TODO : this set needs to only happen in a model doesn't already exist
  // otherwise we reset the model on refresh, which is sad, but if we never set then the ui will break

  const exRolloverModel = await UserSettings.get( GlobalState.get(), 'rolloverModel' )
  if ( !exRolloverModel ) {
    UserSettings.set( 'rolloverModel', rolloverModel )
  }
    
  const exSelectionModel = await UserSettings.get( GlobalState.get(), 'selectionModel' )
  if ( !exSelectionModel ) {
    UserSettings.set( 'selectionModel', selectionModel )
  }
  // const exTableModel = await UserSettings.get( GlobalState.get(), 'tableModel' )
  // if ( !exTableModel ) {
  //   UserSettings.set( 'tableModel', tableModel )
  // }

  // console.log( 'Attributes', Attributes )
  // console.log( 'Attributes', Attributes.getAttributesFor( 'zone' ) )
  // console.log( 'Details', Details.getDetailsFor( 'zone' ) )
  // console.log( 'Plugins', Plugins )
}

exports.run = run
