module.exports = {
  "hierarchy": {
    "rowS": [
      "flex-rowS"
    ],
    "rowM": [
      "flex-rowM"
    ],
    "rowSpaceBetween": [
      "flex-rowSpaceBetween"
    ],
    "rowMEnd": [
      "flex-rowM",
      "flex-rowMEnd"
    ],
    "col": [
      "flex-col"
    ],
    "colS": [
      "flex-col",
      "flex-colS"
    ],
    "colM": [
      "flex-col",
      "flex-colM"
    ],
    "colL": [
      "flex-col",
      "flex-colL"
    ],
    "colXL": [
      "flex-col",
      "flex-colXL"
    ],
    "colXXL": [
      "flex-col",
      "flex-colXXL"
    ],
    "dialogButtons": [
      "flex-rowM",
      "flex-rowMEnd",
      "flex-dialogButtons"
    ]
  },
  "props": {
    "default": {},
    "rowS": {},
    "rowM": {},
    "rowSpaceBetween": {},
    "rowMEnd": {},
    "col": {},
    "colS": {},
    "colM": {},
    "colL": {},
    "colXL": {},
    "colXXL": {},
    "dialogButtons": {}
  }
}