// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".toggle_39kxc {\n  width: 36px;\n  height: 24px;\n  outline: none;\n}\n.toggle_39kxc .background_1Kh2K {\n  height: 100%;\n  border-radius: 24px;\n  background-color: var(--color-base200);\n}\n.toggle_39kxc:focus .background_1Kh2K {\n  background-color: var(--color-base300);\n}\n.toggle_39kxc:hover .background_1Kh2K {\n  background-color: var(--color-base300);\n}\n.toggle_39kxc[data-disabled=true] .background_1Kh2K {\n  background-color: var(--color-base100);\n}\n.toggle_39kxc:active .background_1Kh2K {\n  background-color: var(--color-base200);\n}\n.toggle_39kxc[data-value=true] .background_1Kh2K {\n  background-color: var(--color-brand500);\n}\n.toggle_39kxc:focus[data-value=true] .background_1Kh2K {\n  background-color: var(--color-brand700);\n}\n.toggle_39kxc:hover[data-value=true] .background_1Kh2K {\n  background-color: var(--color-brand700);\n}\n.toggle_39kxc[data-disabled=true][data-value=true] .background_1Kh2K {\n  background-color: var(--color-base100);\n}\n.toggle_39kxc:active[data-value=true] .background_1Kh2K {\n  background-color: var(--color-brand500);\n}\n.toggle_39kxc .handle_1YzNF {\n  width: 16px;\n  margin: 4px 2px 2px;\n  height: 16px;\n  outline: none;\n  border-radius: 50%;\n  background-color: var(--color-white);\n}\n.toggle_39kxc[data-disabled=true] .handle_1YzNF {\n  background-color: var(--color-base300);\n}\n.toggle_39kxc[data-value=true] .handle_1YzNF {\n  transform: translate(15px, 0);\n}\n.toggle_39kxc[data-disabled=true][data-value=true] .handle_1YzNF {\n  background-color: var(--color-base300);\n}\n", ""]);
// Exports
exports.locals = {
	"toggle": "toggle_39kxc",
	"background": "background_1Kh2K",
	"handle": "handle_1YzNF"
};
module.exports = exports;
