const { Constants } = require( '@leverege/api-redux' );

module.exports = {
  ...Constants,

  SYSTEM_LOADING : 'loading.system',
  SYSTEM_LOADED : 'loaded.system',
  SYSTEM_UPDATING : 'updating.system',
  SYSTEM_UPDATED : 'updated.system',

  REGISTRATION_REGISTERING : 'registering.register',
  REGISTRATION_REGISTERED : 'registered.register',
  REGISTRATION_FAILED : 'failed_register',
  REGISTRATION_RESET : 'reset.register',

  FILTER_SET : 'filter.set',
  FILTER_RESET : 'filter.reset',
  FILTER_RESET_ALL : 'filter.reset.all',

  MOMENT_FORMAT : 'MMMM Do YYYY [at] h:mm:ss A',
  MOMENT_SHORT_FORMAT : 'YYYY-MM-DD h:mm A',

  CSV_FORMAT : 'DD-MM-YYYY[_]HH-mmA',

  NOTE_MSG : 'noteMsg',

  LOADING : 'LOADING',

  WEEK : 1000 * 60 * 60 * 24 * 7,
  DAY : 1000 * 60 * 60 * 24,
  COLORS : [ '#D75100', '#4A4A4A', '#3967D6', '#108e72', '#a1a8b0' ],

  DEFAULT_HISTORY_RANGE : 'last7Days',
  DEFAULT_HISTORY_MAX_POINTS : 25,

  WEEK_DAYS : [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ],

  MONTHS : [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'June',
    'July',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.'
  ]
}
