import { Plugins } from '@leverege/plugin'

export const InitialUrlCalculatorFactory = Plugins.createFactory(
  'InitialUrlCalculator',
  { pluginKey : 'calculator', strategy : Plugins.ALL }
)

export function getInitialUrl( activeRole ) {
  const defaultUrl = activeRole?.metadata?.ui?.defaultUrl
  
  if ( typeof defaultUrl === 'string' ) {
    return defaultUrl
  }

  const url = InitialUrlCalculatorFactory.get( defaultUrl )

  if ( typeof url === 'function' ) {
    return url( defaultUrl, activeRole )
  }

  return null
}
