import { Plugins } from '@leverege/plugin'
import { TableModel, Columns, ColumnModel } from '@leverege/ui-attributes'

const INCLUDE_TAG = 'include:table'

function create( blueprint ) {
  const { model } = blueprint

  Plugins.add( 'DefaultTableModel',
    {
      type : model.alias,
      create : ( addName ) => {
        const allColumns = Columns
          .getColumnsFor( model.alias )
          .map( col => col.create() )

        let tModel = TableModel.create()
        const columns = []

        if ( addName ) {
          const nameAttr = allColumns.find( col => col.attrName === `${model.alias}.deviceName` )
          if ( nameAttr ) {
            columns.push( nameAttr )
          }
        }

        if ( model.attributes ) {
          model.attributes
            .forEach( ( attr ) => {
              if ( attr.tags && attr.tags.includes( INCLUDE_TAG ) ) {
                const colAttr = allColumns.find( col => col.attrName === `${model.alias}.${attr.name}` )

                if ( colAttr ) {
                  columns.push( colAttr )
                }
              }
            } )
        }

        tModel = TableModel.setColumns( tModel, columns )

        return tModel
      }
    }
  )
}

exports.create = create
exports.Factory = Plugins.createFactory( 'DefaultTableModel', { pluginKey : 'create', strategy : Plugins.ARGS } )
