import Officers from './Officers'
import Precincts from './Precincts'
import Holsters from './Holsters'
import Zones from './Zones'
  
const registry = {
  officer : Officers,
  precinct : Precincts,
  holster : Holsters,
  zone : Zones,
}

function buildAccessors( { route, alias, finalType } ) {

  let accessors = registry[alias]
  if ( !route || route.length === 0 || !actions ) {
    return null
  }
  route.forEach( ( { path, id } ) => {
    accessors = accessors[path]( id )
  } )

  if ( !( accessors instanceof registry[finalType] ) ) {
    return null
  }
  return accessors.iModel
}

exports.buildAccessors = buildAccessors
  