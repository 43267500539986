import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { getReducers } from '@leverege/api-redux'
import { SelectReducer, SelectionReducer, UIReducer, FilterController, FormReducer } from '@leverege/ui-redux'
import filter, * as FilterStateSelectors from '@leverege/ui-redux/lib/FilterReducer'
import Officers from './Officers'
import Precincts from './Precincts'
import Holsters from './Holsters'
import Zones from './Zones'


const reducers = ( { history } ) => {
  return {
    ui : UIReducer,
    select : SelectReducer,
    selection : SelectionReducer,
    router : connectRouter( history ),
    form : FormReducer,
    filters : filter,
    ...getReducers(),
    officer : Officers,
      precinct : Precincts,
      holster : Holsters,
      zone : Zones,
    }
}

function createSelectors( stateKey, selectors ) {
  const rtn = { }
  Object.keys( selectors ).forEach( ( key ) => {
    if ( key != 'default' ) {
      rtn[key] = ( state, ...args ) => selectors[key]( state[stateKey], ...args )
    }
  } )
  return rtn
}

FilterController.FilterFactory.add( 'includesMany', ( value, filterValue ) => {
  const filterValues = filterValue.split( ',' ).map( val => `${val}`.trim() )

  return filterValues.some( ( thisVal ) => {    
    return thisVal === `${value}`.trim() 
  } )
} )

export default reducers
export const FilterStates = createSelectors( 'filters', FilterStateSelectors )
