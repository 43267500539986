// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".flex_2pEqC {\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: stretch;\n  flex-direction: row;\n  justify-content: flex-start;\n}\n.flex_2pEqC.flex-rowS_-0QTo {\n  display: flex;\n  flex-direction: row;\n}\n.flex_2pEqC.flex-rowM_TrvEv {\n  display: flex;\n  flex-direction: row;\n}\n.flex_2pEqC.flex-rowSpaceBetween_3OPWK {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.flex_2pEqC.flex-rowM_TrvEv.flex-rowMEnd_37fL0 {\n  display: flex;\n  justify-content: flex-end;\n}\n.flex_2pEqC.flex-col_2cOB4 {\n  display: flex;\n  flex-direction: column;\n}\n.flex_2pEqC.flex-col_2cOB4.flex-colS_-O9CI {\n  display: flex;\n  flex-direction: column;\n}\n.flex_2pEqC.flex-col_2cOB4.flex-colM_3rwnK {\n  display: flex;\n  flex-direction: column;\n}\n.flex_2pEqC.flex-col_2cOB4.flex-colL_2Pw4k {\n  display: flex;\n  flex-direction: column;\n}\n.flex_2pEqC.flex-col_2cOB4.flex-colXL_2czaX {\n  display: flex;\n  flex-direction: column;\n}\n.flex_2pEqC.flex-col_2cOB4.flex-colXXL_130mY {\n  display: flex;\n  flex-direction: column;\n}\n.flex_2pEqC.flex-col_2cOB4.flex-colXXL_130mY > * + * {\n  margin: 40px 0 0 0;\n}\n.flex_2pEqC.flex-col_2cOB4.flex-colXL_2czaX > * + * {\n  margin: var(--size-padXXLarge) 0 0 0;\n}\n.flex_2pEqC.flex-col_2cOB4.flex-colL_2Pw4k > * + * {\n  margin: var(--size-padLarge) 0 0 0;\n}\n.flex_2pEqC.flex-col_2cOB4.flex-colM_3rwnK > * + * {\n  margin: var(--size-padMedium) 0 0 0;\n}\n.flex_2pEqC.flex-col_2cOB4.flex-colS_-O9CI > * + * {\n  margin: var(--size-padSmall) 0 0 0;\n}\n.flex_2pEqC.flex-rowM_TrvEv > * + * {\n  margin: 0 0 0 var(--size-padMedium);\n}\n.flex_2pEqC.flex-rowS_-0QTo > * + * {\n  margin: 0 0 0 var(--size-small);\n}\n", ""]);
// Exports
exports.locals = {
	"flex": "flex_2pEqC",
	"flex-rowS": "flex-rowS_-0QTo",
	"flex-rowM": "flex-rowM_TrvEv",
	"flex-rowSpaceBetween": "flex-rowSpaceBetween_3OPWK",
	"flex-rowMEnd": "flex-rowMEnd_37fL0",
	"flex-col": "flex-col_2cOB4",
	"flex-colS": "flex-colS_-O9CI",
	"flex-colM": "flex-colM_3rwnK",
	"flex-colL": "flex-colL_2Pw4k",
	"flex-colXL": "flex-colXL_2czaX",
	"flex-colXXL": "flex-colXXL_130mY"
};
module.exports = exports;
