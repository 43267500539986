// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table_1zx1C {\n  color: var(--color-label1);\n  border: 1px solid var(--color-border1);\n  overflow: hidden;\n  font-family: var(--fontFamily-primary);\n}\n.table_1zx1C .row_owSZh {\n  border-bottom: 1px solid var(--color-border1);\n}\n.table_1zx1C .headerGroup_2ZL_x {\n  z-index: 1;\n  border-bottom: 1px solid var(--color-border1);\n}\n.table_1zx1C .headerCell_J49JU {\n  padding: var(--size-padMedium) var(--size-large);\n  outline: none !important;\n  border-right: 1px solid var(--color-border1);\n}\n.table_1zx1C .headerCell_J49JU:last-child {\n  border: none;\n}\n.table_1zx1C .headerCell_J49JU.sortDesc_3bBUZ {\n  box-shadow: inset 0px -3px var(--color-brand1);\n}\n.table_1zx1C .headerCell_J49JU.sortAsc_3etmc {\n  box-shadow: inset 0px 3px var(--color-brand1);\n}\n.table_1zx1C .bodyCell_2MmCL {\n  padding: var(--size-padMedium) var(--size-large);\n  border-right: 1px solid var(--color-border1);\n}\n.table_1zx1C .bodyCell_2MmCL:last-child {\n  border-right: none;\n}\n.table_1zx1C .bodyCell_2MmCL:first-child {\n  border-left: none;\n}\n", ""]);
// Exports
exports.locals = {
	"table": "table_1zx1C",
	"row": "row_owSZh",
	"headerGroup": "headerGroup_2ZL_x",
	"headerCell": "headerCell_J49JU",
	"sortDesc": "sortDesc_3bBUZ",
	"sortAsc": "sortAsc_3etmc",
	"bodyCell": "bodyCell_2MmCL"
};
module.exports = exports;
