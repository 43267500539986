import { Plugins } from '@leverege/plugin'
import { AttributeColumnModel, AttributeColumnEditor } from '@leverege/ui-attributes'

function installHolsterAttrs() {
  Plugins.add( 'Attribute', {
    id : 'holster.Officer',
    name : 'holster.officer',
    displayName : 'Assigned Officer',
    valueType : 'officerLink',
    objectType : 'holster',
    layout : { group : [] },
    get : ( obj ) => {
      return obj?.data?.holster?.id ? 'Assigned' : 'Unassigned'
    }
  } )

  Plugins.add( 'Model', {
    type : 'attr.column.officerLink',
    model : AttributeColumnModel.createFor( 'attr.column.officerLink' )
  } )


  Plugins.add( 'ModelEditor', {
    type : 'attr.column.officerLink',
    model : AttributeColumnEditor
  } )

  Plugins.add( 'Attribute', {
    id : 'holster.Holster Name',
    name : 'holster.holsterName',
    displayName : 'Holster Name',
    valueType : 'holsterNameLink',
    objectType : 'holster',
    layout : { group : [] },
    get : ( obj ) => {
      return obj?.data?.deviceName || obj?.data?.uid || obj?.data?.name || obj?.name
    }
  } )

  Plugins.add( 'Model', {
    type : 'attr.column.holsterNameLink',
    model : AttributeColumnModel.createFor( 'attr.column.holsterNameLink' )
  } )

  Plugins.add( 'ModelEditor', {
    type : 'attr.column.holsterNameLink',
    model : AttributeColumnEditor
  } )
}

function installOfficerAttrs() {
  Plugins.add( 'Attribute', {
    id : 'officer.assignedHolster',
    name : 'officer.assignedHolster',
    displayName : 'Assigned Holster',
    valueType : 'holsterLink',
    objectType : 'officer',
    layout : { group : [] },
    get : ( obj ) => {
      return obj?.data?.holster?.id ? 'Assigned' : 'Unassigned'
    }
  } )

  Plugins.add( 'Model', {
    type : 'attr.column.holsterLink',
    model : AttributeColumnModel.createFor( 'attr.column.holsterLink' )
  } )

  Plugins.add( 'ModelEditor', {
    type : 'attr.column.holsterLink',
    model : AttributeColumnEditor
  } )

  Plugins.add( 'Attribute', {
    id : 'officer.Name Link',
    name : 'officer.nameLink',
    displayName : 'Last Name',
    valueType : 'officerNameLink',
    objectType : 'officer',
    layout : { group : [] },
    get : ( obj ) => {
      return obj?.data?.name || ''
    }
  } )

  Plugins.add( 'Model', {
    type : 'attr.column.officerNameLink',
    model : AttributeColumnModel.createFor( 'attr.column.officerNameLink' )
  } )

  Plugins.add( 'ModelEditor', {
    type : 'attr.column.officerNameLink',
    model : AttributeColumnEditor
  } )
}
function install() {
  installHolsterAttrs()
  installOfficerAttrs()
}

module.exports = { install }
